import { BrandConfiguration } from "@/types/brand-configuration";

const config: BrandConfiguration = {
  id: "green",
  apiCode: 'GREEN',
  brandName: "Green",
  color: '#6FBE44',
  logoMarkSvg: require("@/assets/brands/green/green-logo-mark.svg"),
};

export default config;
