import { BrandConfiguration } from "@/types/brand-configuration";

const config: BrandConfiguration = {
  id: "liberty",
  apiCode: "LIBERTY",
  brandName: "Liberty",
  color: '#969669',
  logoMarkSvg: require("@/assets/brands/liberty/liberty-logo-mark.svg"),
};

export default config;
