import { BrandConfiguration } from "@/types/brand-configuration";

const config: BrandConfiguration = {
  id: "element",
  apiCode: 'ELEMENT',
  brandName: "Element",
  color: '#F5AD31',
  logoMarkSvg: require("@/assets/brands/element/element-logo-mark.svg"),
};

export default config;
