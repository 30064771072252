import ApiService from "@/modules/shared/services/ApiService";
import { OrderDetailResource } from "../models/OrderDetailResource";
import IShoppingService from "./IShoppingService";

export class ShoppingService extends ApiService implements IShoppingService {

  getOrderExport(): Promise<OrderDetailResource[]> {
    return this.get<OrderDetailResource[]>(`/admin/orders/export`);
  }
  
  public static getInstance(): ShoppingService {
    return new ShoppingService(
      process.env.VUE_APP_SHOPPING_SERVICE as string,
    );
  }

}