import { BrandConfiguration } from "@/types/brand-configuration";

const config: BrandConfiguration = {
  id: "galvan",
  apiCode: "GALVAN",
  brandName: "Galvan",
  color: '#2372B9',
  logoMarkSvg: require("@/assets/brands/galvan/galvan-logo-mark.svg"),
};

export default config;
