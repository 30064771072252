
import { Page, Options, Prop } from '@/common'
import { BrandConfiguration, BrandId } from '@/types/brand-configuration';
import { brandConfigurationsById } from '@/brands';
import { formatters } from '@/formatters';
import { AccountingBrandSummaryCardInfo } from '../types/AccountingSummaryCardInfo';

@Options({
    components: {
    }
})
export default class AccountingBrandSummaryCard extends Page { 
  @Prop() card!: AccountingBrandSummaryCardInfo;
  brandId!: BrandId;
  cardStyle: {[key: string]: string} = {};
  brandConfig: BrandConfiguration | undefined;
  ready = false;

  beforeMount() {
    this.brandId = this.card.brandId;
    this.getBrand();
    if (this.card) {
      this.ready = true;
    }
  }

  private getBrand() {
    const config = this.brandConfig = brandConfigurationsById[this.brandId];
    if (config) {
      this.cardStyle.backgroundColor = config.color;
    }
  }

  get logoMark(): string {
    return this.brandConfig?.logoMarkSvg ?? '';
  }

  formatDollar(amount: number): string {
    return formatters.formatDollar(amount);
  }

  swapCurrency(evt: MouseEvent) {
    evt.stopPropagation();
    
  }

}

