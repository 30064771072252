
export function abbreviateWalletAddress(address: string | null | undefined): string {
  if (address == null) return '--';
  if (address.startsWith('0x') && address.length >10) {
    return address.substring(0, 6) + '...' + address.substring(address.length - 4);
  }
  if (address.length >10) {
    return address.substring(0, 4) + '...' + address.substring(address.length - 4);
  }
  return address;
}
