
import { Page, Options, Prop, Watch, Getter} from '@/common'
import { BrandConfiguration, BrandId } from '@/types/brand-configuration';
import { brandConfigurationsById } from '@/brands';
import { formatters } from '@/formatters';
import { NodeBrandId, nodeConfigurationsById } from '@/config/nodes';
import { AccountingDashboardReportService } from '../services/AccountingDashboardReportService';
import { UserContext } from '@/modules/shared/types/UserContext';
import { OrderTableItem, OrderTableProduct } from '../types/OrderTableItem';
import { AccountingTableReportTransaction, AccountingTableTransactionStatus } from '../models/AccountingTableReport';
import { ShoppingService } from '../services/ShoppingService';
import { OrderDetailItem, OrderDetailResource } from '../models/OrderDetailResource';

interface CsvDocument {
  headers: CsvDataHeader[];
  rows: CsvDataRow[];
}

interface CsvDataHeader {
  id: string;
  title: string;
}

interface CsvDataRow {
  data: {[key: string]: string};
}

@Options({
    components: {
    }
})
export default class AccountingTransactionTable extends Page { 
  @Prop() brandId!: BrandId;
  brandConfig: BrandConfiguration | undefined;
  headerStyle: {[key: string]: string} = {};
  sourceOrders: OrderDetailResource[] = [];
  orders: OrderTableItem[] = [];
  logoMark: string | null = null;
  displayAsCrypto = false;
  selectedOrder: OrderTableItem | null = null;

  // private reportService = AccountingDashboardReportService.getInstance();
  private shoppingService = ShoppingService.getInstance();

  nonce = 0;
  itemCounter = 0;
  ready = false;

  copiedMessageOrderId: string | null = null; // value set temporarily to show alert message
  copiedMessageTimeout: number | undefined;
  
  // startDate = new Date(0);
  @Prop({default: new Date(0)}) startDate!: Date;
  @Prop({default: new Date(0)}) endDate!: Date;

  @Prop({default: ''}) startDateModel!: string;
  @Prop({default: ''}) endDateModel!: string;


  @Getter('authentication/userContext')
  userContext!: UserContext;

  beforeMount() {
    this.reset();
  }


  @Watch('brandId')
  onBrandIdChange(brand: BrandId) {
    this.reset();
  }

  async reset() {
    this.ready = false;
    this.getBrand();
    await this.getTableReport();
    this.ready = true;
  }

  private getBrand() {
    const config = this.brandConfig = brandConfigurationsById[this.brandId];
    this.headerStyle = {};
    this.logoMark = null;
    if (config) {
      this.headerStyle.backgroundColor = config.color;
      this.logoMark = config.logoMarkSvg ?? ''
    }
  }

  async getTableReport() {
    this.orders = [];
    if (!this.brandId) return;
    const nonce = ++this.nonce;

    // const report = await this.reportService.getTableReport(this.userContext, this.brandId, this.startDate, this.endDate);
    const orders = await this.shoppingService.getOrderExport();

    if (nonce != this.nonce) { console.log('Expired Report'); return; }
    // if (report == null) { return; } // TODO: determine how errors should display in UI
    // this.orders = report.transactions.map(t => this.convertTransaction(t));
    // this.orders = this.convertOrders(orders);
    orders.sort((a, b) => { 
      if (Date.parse(a.createdAt) < Date.parse(b.createdAt)) { return -1; }
      if (Date.parse(a.createdAt) > Date.parse(b.createdAt)) { return 1; }
      return 0; 
    });

    this.sourceOrders = orders;
    this.orders = orders.map(o => this.convertOrder(o));
  }

/*
  private convertOrders(orders: OrderDetailResource[]): OrderTableItem[] {
    const items: OrderTableItem[] = [];
    for (const sourceOrder of orders) {
      const item = this.convertOrderItem(sourceItem, sourceOrder);
      for (const sourceItem of sourceOrder.items) {
        const item = this.convertOrderItem(sourceItem, sourceOrder);
        items.push(item);
      }
    }
    return items;
  }
  */

  private convertOrder(order: OrderDetailResource): OrderTableItem {
    const counter = ++this.itemCounter;
    const { member, items } = order;
    const payment = order.payments?.[0];
    const sponsor = order.sponsor;
    const item: OrderTableItem = {
      key: 'k' + counter,
      orderNumber: order.id,
      createdAt: Date.parse(order.createdAt),
      userId: member.id,
      customerName: member.name,
      email: member.email,
      status: order.orderStatus as AccountingTableTransactionStatus,
      invoiceSummary: {
        symbol: order.currencySymbol,
        totalCrypto_string: order.orderTotal,
        totalUSD_string: (Number(order.currencyUsdPrice) * Number(order.orderTotal)).toString(),
        exchangeRate: order.currencyUsdPrice,
      },
      paymentSummary: {
        address: payment?.toWalletAddress ?? '',
        totalConfirmedCrypto_string: order.totalReceived,
        symbol: order.currencySymbol,
        totalConfirmedInUSD_string: (Number(order.currencyUsdPrice) * Number(order.totalReceived)).toString(),
      },
      items: items.map(i => this.convertOrderItem(i, order)),
      nodes: this.getNodeIdsForOrder(order),
      sponsor: sponsor ? { 
        id: sponsor.id,
        name: sponsor.name,
        email: sponsor.email,
      } : undefined,
    };

    return item;
  }

  private convertOrderItem(sourceItem: OrderDetailItem, order: OrderDetailResource): OrderTableProduct {
    const item: OrderTableProduct = {
      itemId: sourceItem.id,
      productName: sourceItem.description ?? '',
      productId: sourceItem.productId,
      quantity: sourceItem.quantity,
      unitPriceSymbol: order.currencySymbol,
      unitPrice: sourceItem.unitPrice,
      // brand: 'win', // TODO: change this.
      nodeLicenseType: 'XX', // order.nodeLicenseType,
    };

    return item;
  }



/*
  private convertTransaction(tx: AccountingTableReportTransaction): OrderTableItem {
    const counter = ++this.itemCounter;
    const { customer, order, product, paymentSummary } = tx;
    const item: OrderTableItem = {
      key: 'k' + counter,
      orderNumber: order.orderNumber,
      createdAt: order.createdAt_ms,
      userId: customer.userId ?? '',
      wpMemberId: customer.wpMemberId ?? '',
      customerName: customer?.name ?? '',
      email: customer?.email ?? '',
      productName: tx.product?.name ?? '',
      productId: tx.product?.id ?? '',
      quantity: order.quantity,
      status: order.status,
      nodeLicenseType: order.nodeLicenseType,
      invoiceSummary: {
        symbol: order.cryptoSymbol,
        totalCrypto_string: order.totalCrypto_string,
        totalUSD_string: order.totalUSD_string,
        exchangeRate: order.cryptoPriceInUSD_string,
      },
      paymentSummary: {
        address: paymentSummary.address,
        totalConfirmedCrypto_string: paymentSummary.totalConfirmedCrypto_string,
        symbol: paymentSummary.cryptoSymbol,
        totalConfirmedInUSD_string: paymentSummary.totalConfirmedInUSD_string,
        totalConfirmedCurrentValueUSD_string: paymentSummary.totalConfirmedCurrentValueUSD_string,
      },
      // transactions: [],
      nodes: this.getNodesByProductIdOrName(product.id, product.name)
    };

    return item;
  }
  */

   private getNodeIdsForOrder(order: OrderDetailResource): NodeBrandId[] {
    const nodes: NodeBrandId[] = [];
    for (const item of order.items) {
      const nodeIds = this.getNodesByProductSku(item.sku);
      for (const nodeId of nodeIds) {
        if (!nodes.includes(nodeId)) {
          nodes.push(nodeId);
        }
      }
    }
    return nodes;
   }

  private getNodesByProductSku(sku: string): NodeBrandId[] {
    const lcase = sku.toLowerCase() ?? '';
    switch (sku) {
      case 'WIN-SMART-NODE': return ['win'];
      case 'WIN-LITE-NODE': return ['win'];
      case 'START': return ['win'];
      case 'GREEN-SMART-NODE': return ['green'];
      case 'GREEN-LITE-NODE': return ['green'];
      case 'SWITCH-SMART-NODE': return ['switch'];
      case 'SWITCH-LITE-NODE': return ['switch'];
      case 'GALVAN-SMART-NODE': return ['galvan'];
      case 'GALVAN-LITE-NODE': return ['galvan'];
      case 'LIBERTY-SMART-NODE': return ['liberty'];
      case 'LIBERTY-LITE-NODE': return ['liberty'];
      case 'ELEMENT-SMART-NODE': return ['element'];
      case 'ELEMENT-LITE-NODE': return ['element'];
      case 'BUNDLE-B4': return ['win', 'green', 'switch', 'galvan'];
      case 'BUNDLE-C5': return ['win', 'green', 'switch', 'galvan', 'element'];
    }
    return [];
  }


  private getNodesByProductIdOrName(id?: string, name?: string): NodeBrandId[] {
    const lcase = name?.toLowerCase() ?? '';
    if (this.brandId=== 'connect' && lcase.includes('bundle')) { return ['win','green', 'galvan', 'switch', 'liberty']; }
    if (lcase.includes('green')) { return ['green']; }
    if (lcase.includes('blue')) { return ['galvan']; }
    if (lcase.includes('galvan')) { return ['galvan']; }
    if (lcase.includes('switch')) { return ['switch']; }
    if (lcase.includes('liberty')) { return ['liberty']; }
    if (lcase.includes('give')) { return ['give']; }
    if (lcase.includes('win')) { return ['win']; }
    if (lcase.includes('element')) { return ['element']; }
    return [];
  }

  formatDate(ts: number) {
    return formatters.formatDate(ts, 'date');
  }

  formatTime(ts: number) {
    return formatters.formatDate(ts, 'time');
  }

  formatUSD(value: number) {
    return formatters.formatUSD(value);
  }

  shortAddress(address: string) {
    return formatters.abbreviateWalletAddress(address);
  }

  shortId(id: string) {
    return formatters.abbreviateWalletAddress(id);
  }

  toggleCurrency() {
    this.displayAsCrypto = !this.displayAsCrypto;
  }

  async copyAddressToClipboard(order: OrderTableItem) {
    const address = order.paymentSummary?.address;
    if (address == null) return;
    await navigator.clipboard.writeText(address);
    this.showCopiedMessage(order.key);
  }

  private showCopiedMessage(orderKey: string) {
    this.copiedMessageOrderId = orderKey;
    if (this.copiedMessageTimeout) { clearTimeout(this.copiedMessageTimeout); }
    this.copiedMessageTimeout = setTimeout(() => { this.copiedMessageOrderId = null; this.copiedMessageTimeout = undefined; }, 2500) as any;
  }

  openAddressForOrder(order: OrderTableItem) {
    window.open("https://etherscan.io/address/" + order.paymentSummary.address, '_blank');
  }

  getInvoiceValueFormatted(order: OrderTableItem): string {
    if (this.displayAsCrypto) { 
      return formatters.formatCrypto(order.invoiceSummary.totalCrypto_string, order.invoiceSummary.symbol, true);
    }
    else {
      return formatters.formatUSD(order.invoiceSummary.totalUSD_string);
    }
  }

  getReceivedValueFormatted(order: OrderTableItem): string {
    if (this.displayAsCrypto) { 
      return formatters.formatCrypto(order.paymentSummary.totalConfirmedCrypto_string, order.paymentSummary.symbol, true);
    }
    else {
      return formatters.formatUSD(order.paymentSummary.totalConfirmedInUSD_string);
    }
  }

  statusClassForOrder(order: OrderTableItem): 'status-success' | 'status-fail' | 'status-pending' | 'status-alert' | null {
    if (order.status === 'complete') return 'status-success';
    if (order.status === 'expired') return 'status-fail';
    if (order.status === 'insufficient') return 'status-alert';
    if (order.status === 'pending') return 'status-pending';
    if (order.status === 'confirming') return 'status-pending';
    return null;
  }

  statusIconForOrder(order: OrderTableItem): string | null {
    if (order.status === 'complete') return 'hi-check';
    if (order.status === 'expired') return 'hi-x';
    if (order.status === 'insufficient') return 'exclamation';
    if (order.status === 'pending') return 'hi-clock';
    if (order.status === 'confirming') return 'hi-clock';
    return null;
  }

  isFullyPaid(order: OrderTableItem): boolean {
    if (order.paymentSummary.symbol !== order.invoiceSummary.symbol) return false;
    return order.status === 'complete';
    // return (order.paymentSummary.totalConfirmed >= order.invoiceSummary.total);
  }

  nodeAbbreviationForId(nodeId: NodeBrandId): string {
    const config = nodeConfigurationsById[nodeId];
    if (config == null) return '';
    return config.abbreviation;
  }

  nodeBadgeStyle(nodeId: NodeBrandId): {[key: string]: string} {
    const config = nodeConfigurationsById[nodeId];
    if (config == null) return {};
    // return { backgroundColor: config.color };
    return { backgroundColor: this.hexToRgbA(config.color, 0.2), color: config.color };
  }

  private hexCache: {[key: string]: number[]} = {};
  hexToRgbA(hex: string, opacity: number){
    let rgb = this.hexCache[hex];
    if(rgb == null && /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        let c: any = hex.substring(1).split('');
        if(c.length== 3){ c= [c[0], c[0], c[1], c[1], c[2], c[2]]; }
        c= '0x'+c.join('');
        rgb = [(c>>16)&255, (c>>8)&255, c&255];
        this.hexCache[hex] = rgb;
    }
    if (rgb == null) return '';
    return 'rgba('+rgb.join(',')+', '+ opacity +')';
  }

  nodeNameForId(nodeId: NodeBrandId): string {
    const config = nodeConfigurationsById[nodeId];
    if (config == null) return '';
    return config.name;
  }

  selectRow(order: OrderTableItem) {
    this.selectedOrder = order;
  }

  
  downloadCSV() {
    const doc: CsvDocument = {
      headers: [
        // { id: 'channel', title: 'Sales Channel' },
        { id: 'orderId', title: 'Order ID' },
        { id: 'name', title: 'Member Name' },
        { id: 'email', title: 'Email' },
        // member number
        // mongo ID
        { id: 'memberId', title: 'Member ID (New System)' },
        { id: 'date', title: 'Created Date' },
        { id: 'symbol', title: 'Currency Symbol' },
        { id: 'orderTotal', title: 'Order Total' },
        { id: 'totalReceived', title: 'Total Received' },
        { id: 'exchangeRate', title: 'Currency USD Price' },
        { id: 'status', title: 'Order Status' },

        { id: 'paymentId', title: 'Payment ID' },
        { id: 'paymentAmount', title: 'Payment Amount' },
        { id: 'transactionHash', title: 'Transaction Hash' },
        { id: 'toWalletAddress', title: 'To Wallet Address' },
        { id: 'fromWalletAddress', title: 'From Wallet Address' },
        { id: 'paymentStatus', title: 'Payment Status' },
        
        { id: 'orderItemId', title: 'Order Item ID' },
        { id: 'productId', title: 'Product ID' },
        { id: 'productName', title: 'Product Name' },
        { id: 'sku', title: 'Product SKU' },
        { id: 'unitPrice', title: 'Unit Price' },
        { id: 'quantity', title: 'Quantity' },
        { id: 'lineTotal', title: 'Line Total' },
      ],
      rows: [],
    };

    const brandName = this.brandConfig?.brandName ?? this.brandId;

    for (const order of this.sourceOrders) {
      let dateString = '';
      if (order.createdAt != null) { 
        const d = new Date(order.createdAt);
        dateString = `${(d.getMonth() + 1)}/${d.getDate()}/${d.getFullYear()}`;
      }

      const payment = order.payments?.[0];

      const row: CsvDataRow = {
        data: {
          orderId: order.id,
          name: order.member.name,
          email: order.member.email,
          memberId: order.member.id,
          date: dateString,
          symbol: order.currencySymbol,
          orderTotal: order.orderTotal,
          totalReceived: order.totalReceived,
          exchangeRate: order.currencyUsdPrice,
          status: order.orderStatus,
          paymentId: payment?.id ?? '',
          paymentAmount: payment?.amount ?? '',
          transactionHash: payment?.transactionHash ?? '',
          toWalletAddress: payment?.toWalletAddress ?? '',
          fromWalletAddress: payment?.fromWalletAddress ?? '',
          paymentStatus: payment?.status ?? '',
        },
      }
      doc.rows.push(row);

      for (const sourceItem of order.items) {
        const row: CsvDataRow = {
          data: {
            orderId: '(order line)',
            orderItemId: sourceItem.id,
            productId: sourceItem.productId,
            productName: sourceItem.description ?? '',
            sku: sourceItem.sku,
            unitPrice: sourceItem.unitPrice,
            quantity: sourceItem.quantity.toString(),
            lineTotal: sourceItem.lineTotal
          },
        }
        doc.rows.push(row);
      }

        doc.rows.push({ data: {} });
    }

    let csvContent = "data:text/csv;charset=utf-8," 
    + doc.headers.map(h => h.title).join(',') + '\n'
    + doc.rows.map(r => doc.headers.map(h => (r.data[h.id] ?? '').replace(/,/g, '')).join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    // link.setAttribute("download", `${brandName}-sales-report-${this.startDateModel}-to-${this.endDateModel}.csv`);
    const d = new Date();
    const today = `${d.getFullYear()}-${ this.twoDig(d.getMonth() + 1) }-${ this.twoDig(d.getDate()) }`;
    link.setAttribute("download", `${brandName}-sales-report-${today}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  twoDig(input: number): string { const s = input.toString(); if (s.length === 1) return '0' + s; return s;
  }


  dateToShortString(date?: Date): string {
    if (date == null) return '';
    return `${(date.getMonth()+1)}-${date.getDate()}-${date.getFullYear()}`;

  }

  toNumber(input: string | null): number | null {
    if (input == null) return null;
    if (input === '') return null;
    const num = Number(input);
    if (isNaN(num)) return null;
    return num;
  }

  /*
    get logoMark(): string {
      console.log('logoMark');
      return this.brandConfig?.logoMarkSvg ?? '';
    }
  */

}

