
export type NodeBrandId = 'give' | 'green' | 'galvan' | 'switch' | 'liberty' | 'win' | 'element';

export interface NodeConfiguration {
  id: NodeBrandId;
  name: string;
  color: string;
  abbreviation: string;
}

const nodeConfigurations: NodeConfiguration[] = [
  /*
  {
    id: 'connect',
    color: '#4373B8',
    name: "Connect",
    abbreviation: 'C',
  },
  */
  {
    id: 'give',
    color: '#EF3C3A',
    name: "Give",
    abbreviation: 'GV',
  },
  {
    id: 'green',
    color: '#6FBE44',
    name: "Green",
    abbreviation: 'GR',
  },
  {
    id: 'galvan',
    color: '#2372B9',
    name: "Galvan",
    abbreviation: 'G',
  },
  {
    id: 'switch',
    color: '#4D8ECB',
    name: "Switch",
    abbreviation: 'S',
  },
  {
    id: 'liberty',
    color: '#969669',
    name: "Liberty",
    abbreviation: 'L',
  },
  {
    id: 'win',
    color: '#F7941E',
    name: "Win",
    abbreviation: 'W',
  },
  {
    id: 'element',
    color: '#F5AD31',
    name: "Element",
    abbreviation: 'E',
  },
];


const nodeConfigurationsById: {[key: string]: NodeConfiguration } = {};

for (const node of nodeConfigurations) { 
  nodeConfigurationsById[node.id] = node;
}


export { nodeConfigurations, nodeConfigurationsById }

