

import { Page, Options, Getter, Watch } from '@/common'
import AccountingBrandSummaryCard from '../components/AccountingBrandSummaryCard.vue';
import AccountingTransactionTable from '../components/AccountingTransactionTable.vue';
import { activeBrandIds } from '@/brands';
import { BrandApiCode, BrandId } from '@/types/brand-configuration';
import { brandConfigurationsById } from '@/brands';
import { formatters } from '@/formatters';
import { AccountingDashboardReportService } from '../services/AccountingDashboardReportService';
import { ShoppingService } from '../services/ShoppingService';
import { UserContext } from '@/modules/shared/types/UserContext';
import { AccountingBrandSummaryCardInfo, AccountingBrandSummaryCardProduct } from '../types/AccountingSummaryCardInfo';
import { AccountingHeaderReport, AccountingHeaderReportProduct } from '../models/AccountingHeaderReport';


@Options({
    components: {
      AccountingBrandSummaryCard,
      AccountingTransactionTable,
    },
})
export default class AccountingDashboardPage extends Page { 
  totalNodes = 0;
  totalRevenue = 0;
  cards: AccountingBrandSummaryCardInfo[] = [];
  activeBrandCodes: BrandApiCode[] = [];
  currentBrandId: BrandId | null = null;
  private divCardArea: HTMLDivElement | undefined;
  private divCards: HTMLDivElement | undefined;
  private lastHeight = 0;
  private intervalId: number | undefined;

  private reportService = AccountingDashboardReportService.getInstance();
  private shoppingService = ShoppingService.getInstance();
  
  nonce = 0;

  @Getter('authentication/userContext')
  userContext!: UserContext;

  startDateModel = '';
  endDateModel = '';

  startDate = new Date(0);
  endDate = new Date(0);

  @Watch('startDateModel') 
  startDateModelChanged(startDateString: string) {
    // console.log({ startDateString });
    this.reload();
  }

  @Watch('endDateModel') 
  endDateModelChanged(endDateString: string) {
    // console.log({ endDateString });
    this.reload();
  }

  beforeMount() {
    if (this.$route.name === 'accounting.dashboard.all') this.startDate = new Date(0);

    const d = new Date();
    this.startDateModel = `${d.getFullYear()}-${ this.twoDig(d.getMonth() + 1) }-${ this.twoDig(1) }`;
    const endDate = new Date(d.getFullYear(), d.getMonth(), 1);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(endDate.getDate() - 1);
    this.endDateModel = `${endDate.getFullYear()}-${ this.twoDig(endDate.getMonth() + 1) }-${ this.twoDig(endDate.getDate()) }`;
    
    this.cards = [];
    this.activeBrandCodes = [];

    for (const brandId of activeBrandIds) {
      const config = brandConfigurationsById[brandId];
      if (config) {
        this.activeBrandCodes.push(config.apiCode);
        this.cards.push({
          ready: false,
          brandId: brandId,
          apiCode: config.apiCode,
          totalRevenueUsd_string: null,
          products: [],
          bundledProducts: []
        });
      }
    }
  }

  twoDig(input: number): string {
    const s = input.toString();
    if (s.length === 1) return '0' + s;
    return s;
  }

  async reload() {
    this.currentBrandId = null;
    if ((this.startDateModel?.length ?? 0) === 0) return;
    if ((this.endDateModel?.length ?? 0) === 0) return;
    const startDate = new Date(this.startDateModel);
    const endDate = new Date(this.endDateModel);
    startDate.setTime(startDate.getTime() + 7*60*60*1000); // TODD: implement better conversion to MST
    endDate.setTime(endDate.getTime() + 7*60*60*1000 + 24*60*60*1000); // TODD: implement better conversion to MST
    this.startDate = startDate;
    this.endDate = endDate;
    // await this.loadCardData();
    this.currentBrandId = 'connect';
  }

  mounted() {
    this.updateDivs();
    this.checkSize();
    this.intervalId = setInterval(()=>{ this.checkSize(); }, 200) as any;
  }

  updated() {
    this.updateDivs();
    this.checkSize();
  }

  beforeUnmount() {
    if (this.intervalId != null) { clearInterval(this.intervalId); }
  }

  private updateDivs() {
    this.divCardArea = this.$el.getElementsByClassName('card-area')[0];
    this.divCards = this.$el.getElementsByClassName('cards')[0];
  }

  private checkSize() {
    if (!(this.divCardArea && this.divCards)) return;
    const height = this.divCards.offsetHeight;
    if (height === this.lastHeight) return;
    this.lastHeight = height;
    this.divCardArea.style.minHeight = Math.max(height, 100) + 'px';
  }

  selectCard(card: AccountingBrandSummaryCardInfo) {
    this.selectBrand(card.brandId);
  }

  selectBrand(brandId: BrandId) {
    this.currentBrandId = brandId;
  }

  formatDollar(amount: number): string {
    return formatters.formatDollar(amount);
  }

  async loadCardData() {
    const nonce = ++this.nonce;

    // const result = await this.reportService.getHeaderReport(this.userContext, this.activeBrandCodes, this.startDate, this.endDate);
    const orders = await this.shoppingService.getOrderExport();
    if (nonce != this.nonce) { console.log('Expired Report'); return; }

    /*

    let totalNodes = 0;
    let totalRevenue = 0;
    for (const brand of result.brands) {
      // const a = brand.products.reduce((pv, cv) => ({ return 5 + 5; })), 0);
      const newRevenue = Number(brand.totalRevenue_string);
      if (!isNaN(newRevenue)) {totalRevenue += newRevenue; }        

      totalNodes = totalNodes
        + (brand.products?.map(p => p.quantitySold).reduce((p, c) => p + c, 0) ?? 0)
        + (brand.connectProducts?.map(p => p.quantitySold).reduce((p, c) => p + c, 0) ?? 0);

      const card = this.cards.find(c => c.apiCode === brand.apiCode);
      if (card) {
        card.totalRevenueUsd_string = brand.totalRevenue_string;
        card.products = brand.products.map((p, index) => this.convertHeaderProduct(p, index));
        card.bundledProducts = brand.connectProducts.map((p, index) => this.convertHeaderProduct(p, index));
        card.ready = true;
      }
    }
    this.totalNodes = totalNodes;
    this.totalRevenue = totalRevenue;
    */

  }

  private convertHeaderProduct(p: AccountingHeaderReportProduct, index: number): AccountingBrandSummaryCardProduct {
    const product: AccountingBrandSummaryCardProduct = {
      key: '' + index,
      name: p.name,
      quantitySold: p.quantitySold,
    };
    return product;
  }




}
