import { BrandConfiguration } from "@/types/brand-configuration";

const config: BrandConfiguration = {
  id: "switch",
  apiCode: "SWITCH",
  brandName: "Switch",
  color: '#4D8ECB',
  logoMarkSvg: require("@/assets/brands/switch/switch-logo-mark.svg"),
};

export default config;
