import { BrandConfiguration } from "@/types/brand-configuration";

const config: BrandConfiguration = {
  id: "give",
  apiCode: "GIVE",
  brandName: "Give",
  color: '#EF3C3A',
  logoMarkSvg: require("@/assets/brands/give/give-logo-mark.svg"),
};

export default config;
