
type FormatterId = 'USD' | 'USD.integer';
export type CurrencyFormat = 'USD';

const currencyFormatters: {[key: string]: Intl.NumberFormat} = {};
function getCurrencyFormatter(id: FormatterId): Intl.NumberFormat | null {
  if (!window.Intl) return null;

  let formatter = currencyFormatters[id];
  if (formatter != null) return formatter;

  const options: Intl.NumberFormatOptions = {};

  if (id === 'USD' || id === 'USD.integer') {
    options.style = 'currency';
    options.currency = 'USD';
    if (id === 'USD.integer') { options.maximumFractionDigits = 0; }
    formatter = new Intl.NumberFormat('en-US', options);
  }

  if (formatter == null) return null;
  currencyFormatters[id] = formatter;
  return formatter;
}

export function formatUSD(value: number | string | null | undefined): string {
  return formatWith(value, 'USD');
}

export function formatDollar(value: number | string | null | undefined): string {
  return formatWith(value, 'USD.integer');
}

function formatWith(value: number | string | null | undefined, formatterId: FormatterId): string {
  if (value == null) return '--';
  if (isNaN(Number(value))) { return '--'; }
  const numberValue = Number(value);
  const f = getCurrencyFormatter(formatterId);
  if (f != null) { return f.format(numberValue); }
  return value.toLocaleString();
}


export function formatCurrency(value: number, format: CurrencyFormat):string {
  switch(format) {
    case 'USD': return formatWith(value, 'USD');
  }
  return '';
}

export function formatCrypto(value: number | string | null | undefined, symbol: string, includeSymbol: boolean):string {
  const suffix = (includeSymbol && symbol != null && symbol.length > 0) ? ' ' + symbol : '';
  if (value == null) return '--' + suffix;
  if (isNaN(Number(value))) { return '--' + suffix; }
  const numberValue = Number(value);
  const s = truncateNumber(numberValue, 8, 0.1);
  return s + suffix;
}

function truncateNumber(value: number, minTrailingDigits: number, maxVariancePct: number): string {
  if (value === 0) return '0';

  let trailingDigits = minTrailingDigits;
  let stringValue = '';

  for (let i=0; i<30; i++) {
    stringValue = value.toFixed(trailingDigits);
    const variancePct = Math.abs(1 - (Number(stringValue) / value)) * 100;
    if (variancePct <= maxVariancePct) break;
    trailingDigits++;
  }

  if (stringValue.includes('.') && stringValue.endsWith('0')) {
    return stringValue.replace(/\.?0*$/, '');
  }

  return stringValue;
}
