import { BrandConfiguration, BrandId } from '@/types/brand-configuration';
import connect from './connect';
import green from './green';
import give from './give';
import switchConfig from './switch';
import galvan from './galvan';
import liberty from './liberty';
import element from './element';


const brandConfigurations: BrandConfiguration[] = [
  connect,
  give,
  green,
  galvan,
  switchConfig,
  liberty,
  element,
];

const activeBrandIds: BrandId[] = [
  connect.id,
  give.id,
  green.id,
  galvan.id,
  switchConfig.id,
  liberty.id,
  element.id,
];


const brandConfigurationsById: {[key: string]: BrandConfiguration } = {};

for (const brand of brandConfigurations) { 
  brandConfigurationsById[brand.id] = brand;
}


export { activeBrandIds, brandConfigurations, brandConfigurationsById };
