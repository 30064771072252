import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
import headerReportQuery from '@/modules/accounting/gql/headerReport.gql';
import tableReportQuery from '@/modules/accounting/gql/tableReport.gql';
import { AccountingTableReport, AccountingTableReportTransaction } from '../models/AccountingTableReport';
import { UserContext } from '@/modules/shared/types/UserContext';
import { AccountingHeaderReport, AccountingHeaderReportBrand, AccountingHeaderReportProduct } from '../models/AccountingHeaderReport';
import { BrandApiCode } from '@/types/brand-configuration';

export class AccountingDashboardReportService {

  private getClient(context: UserContext): ApolloClient<NormalizedCacheObject> {
    // TODO: return the same client for the same token
    const client = new ApolloClient({
      uri: process.env.VUE_APP_SERVER,
      cache: new InMemoryCache(),
      headers: {
        authorization: `Bearer ${ (context?.token ?? '') }`,
      }
    });        
    return client;
  }

  public async getHeaderReport(context: UserContext, brands:BrandApiCode[], startDate: Date, endDate: Date): Promise<AccountingHeaderReport | null>  {
    try {
      const client = this.getClient(context);

      const fetchResult = await client.query({
        query: headerReportQuery,
        variables: {
          brandList: brands.map(code => ({ brand: code, startDate: startDate.getTime(), endDate: endDate.getTime() })),
        },
      });

      const brandReports: AccountingHeaderReportBrand[] = [];
      const reports = fetchResult?.data?.multiBrandGetCartTransactionsHeaderReport;

      if (reports && Array.isArray(reports)) {
        for (const report of reports) {
          const reportNode = report.headerReportOrError;
          const productCategory = reportNode?.productsByCategory?.find((c: any) => c.category === 'PURCHASED_IN_CURRENT_BRAND');
          const connectCategory = reportNode?.productsByCategory?.find((c: any) => c.category === 'PURCHASED_IN_CONNECT_BRAND');
          const brandReport: AccountingHeaderReportBrand = {
            apiCode: report.brand,
            totalRevenue_string: reportNode?.grandTotalRevenueUsdSum,
            products: productCategory?.products?.map((p: any) => this.mapHeaderProduct(p)) ?? [],
            connectProducts: connectCategory?.products?.map((p: any) => this.mapHeaderProduct(p)) ?? [],
          };
          brandReports.push(brandReport);
        }
      }

      return {
        // totalNodes: 0,
        // totalRevenue: 0,
        brands: brandReports,
       };
        
    } catch (error: any) {
      const hasForbiddenError = error.graphQLErrors?.find((ge: any) => ge.extensions?.code === 'FORBIDDEN') != null;
      // if (hasForbiddenError) { throw new Error('FORBIDDEN'); }
      return null;
    }    

  }

  private mapHeaderProduct(p: any): AccountingHeaderReportProduct  {
    return {
      name: p.productName,
      orderCount: p.transactionsCount,
      quantitySold: p.quantityPurchasedSum,
      revenueUsd_string: p.totalRevenueUsdSum,
    };
  }

  public async getTableReport(context: UserContext, brand: string, startDate: Date, endDate: Date): Promise<AccountingTableReport | null>  {
    try {

      const client = this.getClient(context);
      const fetchResult = await client.query({
        query: tableReportQuery,
        variables: {
          brandList: [{ brand: brand.toUpperCase(), startDate: startDate.getTime(), endDate: endDate.getTime() }],
        },
      });
      
      let transactions: AccountingTableReportTransaction[] | undefined;
      // console.log(fetchResult);
      const cartTransactions = fetchResult.data?.multiBrandGetCartTransactionsDetailReport?.[0]?.detailReportOrError?.cartTransactions;
      if (cartTransactions) {
        
        transactions = cartTransactions?.map((tx: any) => {

          // Calculatet totalConfirmedUSD (not provided in API)
          let totalConfirmedUsd: string | null = null;
          if (tx.totalCryptoReceived != null && tx.totalCryptoReceived.length > 0 && tx.conversionRate != null && tx.conversionRate.length > 0) {
            const cryptoReceived = Number(tx.totalCryptoReceived);
            const conversionRate = Number(tx.conversionRate);
            if (!(isNaN(cryptoReceived) || isNaN(conversionRate))) {
              totalConfirmedUsd = (Math.floor(100 * cryptoReceived * conversionRate) / 100).toString();
            }
          }

          const quantity: number = tx.quantity;
          let unitPriceInCrypto: string | null = null;
          if (tx.totalCrypto != null && quantity != null && quantity > 0) {
            const totalCrypto = Number(tx.totalCrypto);
            if (!isNaN(totalCrypto)) {
              unitPriceInCrypto = (totalCrypto / quantity).toString()
            }
          }

          let symbol = tx.cryptoCurrency;
          if (symbol === 'Currency Unknown') symbol = 'UNKNOWN';

          const transaction: AccountingTableReportTransaction = {
            customer: {
              name: tx.name,
              email: tx.email,
              wpMemberId: tx.memberId,
              userId: tx.userId,
            },
            order: {
              status: tx.status,
              orderNumber: tx.orderNumber,
              createdAt_ms: tx.created,
              cryptoSymbol: symbol,
              totalUSD_string: tx.totalUsd,
              totalCrypto_string: tx.totalCrypto,
              unitPriceCrypto_string: unitPriceInCrypto,
              quantity: quantity,
              cryptoPriceInUSD_string: tx.conversionRate,
              nodeLicenseType: tx.nodeLicenseType
            },
            paymentSummary: {
              address: tx.address,
              cryptoSymbol: symbol,
              totalConfirmedCrypto_string: tx.totalCryptoReceived,
              totalConfirmedInUSD_string: totalConfirmedUsd,
              totalConfirmedCurrentValueUSD_string: tx.currentValue,
            },
            product: {
              id: tx.productId,
              name: tx.productName,
            },
          };
          return transaction;
        });
      }

      const report: AccountingTableReport = { transactions: transactions ?? [] };
      return report;

    } catch (error) {
      return null;
    }

  }

  public static getInstance(): AccountingDashboardReportService {
    return new AccountingDashboardReportService();
  }

}