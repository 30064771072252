
export type DateFormat = 'default' | 'date' | 'time';
// default = Locale Specific Format
// datetime = Jan 1, 2020 12:34 PM
// date = Jan 1, 2020


const dateFormatters: {[key: string]: Intl.DateTimeFormat} = {};
function getDateFormatter(id: DateFormat): Intl.DateTimeFormat | null {
  if (!window.Intl) return null;

  let formatter = dateFormatters[id];
  if (formatter != null) return formatter;

  const options: Intl.DateTimeFormatOptions = {};
  
  if (id === 'default') {
    options.day = 'numeric';
    options.month = 'short';
    options.year = 'numeric';
    options.hour = 'numeric';
    options.minute = '2-digit';
    formatter = new Intl.DateTimeFormat(undefined, options);
  }

  if (id === 'date') {
    options.day = 'numeric';
    options.month = 'short';
    options.year = 'numeric';
    formatter = new Intl.DateTimeFormat(undefined, options);
  }

  if (id === 'time') {
    options.hour = 'numeric';
    options.minute = '2-digit';
    // options.month = 'short';
    // options.year = 'numeric';
    formatter = new Intl.DateTimeFormat(undefined, options);
  }

  if (formatter == null) return null;
  dateFormatters[id] = formatter;
  return formatter;
}

function formatDateStandard(date: Date):string {
  const f = getDateFormatter('default');
  if (f) { return f.format(date); }
  return date.toLocaleString();
}

function formatDateOnly(date: Date):string {
  const f = getDateFormatter('date');
  if (f) { return f.format(date); }
  return date.toLocaleDateString();
}

function formatTimeOnly(date: Date):string {
  const f = getDateFormatter('time');
  if (f) { return f.format(date); }
  return date.toLocaleTimeString();
}

export function formatDate(timestampInMs: number, format?: DateFormat):string {
  if (timestampInMs == null) return '';
  if (format == null) format = 'default';
  const date = new Date(timestampInMs);
  switch(format) {
    case 'default': return formatDateStandard(date);
    case 'date': return formatDateOnly(date);
    case 'time': return formatTimeOnly(date);
  }
  return '';
}
